import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "./productCard.css"
import { capitalizeFirstLetter } from "../utilityFunctions"

const ProductCard = ({ product, allImageData, cardDetails, miscSettings }) => {
  let image = allImageData.edges.find(n => {
    return n.node.fixed.src.includes(product.local_image_name)
  })

  if (!image) {
    console.log(
      "WARNING: Could not find image for " +
        product.full_name +
        "(" +
        product.local_image_name +
        ")"
    )
    image = allImageData.edges.find(n => {
      return n.node.fixed.src.includes("image_not_found.png")
    })
  }

  let price_string = Math.min(product.prices) + " kr"
  if (product.length === 1) {
    price_string = "Från " + Math.min(product.prices) + " kr"
  }

  let price_class = ""
  let original_price_string = ""
  // TODO: Implement rea-price
  /*
  if (product.min_price !== product.min_original_price) {
    price_class = "rea"
    original_price_string = product.min_original_price + " kr"
  }
  */
  let storeLink = product.url
  let internalProductLink = product.url
  let target = "_blank"
  const PRODUCT_PATH = miscSettings["product_root_path"]
  //TODO: Code below is duplicated, handle dynamically better (difference is Link/OutboundLink)
  if (miscSettings["has_product_page"] === "y") {
    internalProductLink =
      PRODUCT_PATH + "/" + product.fields.full_name_url + "/"
    target = ""
    return (
      <div className={"product"}>
        <div>
          <OutboundLink
            href={storeLink}
            rel="nofollow noreferrer sponsored"
            target={target}
          >
            <div>
              {
                <Img
                  fixed={image.node.fixed}
                  alt={product.full_name}
                  title={product.full_name}
                />
              }
            </div>
            <div className="name">{product.full_name}</div>
            <div className="properties">
              {cardDetails.map((cardDetail, index) => {
                let propKeyArr = cardDetail.key.split(".")
                let current_node = product
                propKeyArr.forEach(function (propKey, propIndex) {
                  current_node = current_node[propKey]
                })

                let prefixSuffix = cardDetail.value.split(";")
                return (
                  <span>
                    {prefixSuffix[0]}
                    {capitalizeFirstLetter(current_node)}
                    {prefixSuffix[1]}
                  </span>
                )
              })}
            </div>

            {/*
              <div className="properties">
                {product["derived_properties"].the_length.length > 1
                  ? Math.min(...product["derived_properties"].the_length) +
                    " - " +
                    Math.max(...product["derived_properties"].the_length)
                  : product["derived_properties"].the_length}{" "}
                cm - {product["derived_properties"].color.join(", ")}
              </div>
              */}
            <div className="price">
              <p className={price_class}>{price_string}</p>
              <p className="original_price">{original_price_string}</p>
            </div>
          </OutboundLink>
          <div class="info-buy">
            <OutboundLink
              href={storeLink}
              rel="nofollow noreferrer sponsored"
              target={target}
            >
              <div className="to-store">Till Butiken</div>
            </OutboundLink>
            <Link to={internalProductLink}>
              <div class="info-button"></div>
            </Link>
          </div>
          {/*
              <div className="description">
                {product.description.replace(/&nbsp/g, " ").replace(/  /g, " ")}
              </div>
            */}
        </div>
      </div>
    )
  } else {
    return (
      <div className={"product"}>
        <OutboundLink
          href={storeLink}
          rel="nofollow noreferrer sponsored"
          target={target}
        >
          <div>
            <div>
              {
                <Img
                  fixed={image.node.fixed}
                  alt={product.full_name}
                  title={product.full_name}
                />
              }
            </div>
            <div className="name">{product.full_name}</div>
            <div className="properties">
              {cardDetails.map((cardDetail, index) => {
                let propKeyArr = cardDetail.key.split(".")
                let current_node = product
                propKeyArr.forEach(function (propKey, propIndex) {
                  current_node = current_node[propKey]
                })

                let prefixSuffix = cardDetail.value.split(";")
                return (
                  <span>
                    {prefixSuffix[0]}
                    {capitalizeFirstLetter(current_node)}
                    {prefixSuffix[1]}
                  </span>
                )
              })}
            </div>

            {/*
              <div className="properties">
                {product["derived_properties"].the_length.length > 1
                  ? Math.min(...product["derived_properties"].the_length) +
                    " - " +
                    Math.max(...product["derived_properties"].the_length)
                  : product["derived_properties"].the_length}{" "}
                cm - {product["derived_properties"].color.join(", ")}
              </div>
              */}
            <div className="price">
              <p className={price_class}>{price_string}</p>
              <p className="original_price">{original_price_string}</p>
            </div>
            <div className="to-store">Läs mer & Köp</div>
            {/*
              <div className="description">
                {product.description.replace(/&nbsp/g, " ").replace(/  /g, " ")}
              </div>
            */}
          </div>
        </OutboundLink>
      </div>
    )
  }
}

export default ProductCard

import React from "react"
import "./productDisplay.css"
import { useStaticQuery, graphql } from "gatsby"
import DropDown from "./filter"
import queryString from "query-string"
import ProductCard from "./productCard"
import config from "../config.js"

const SortDropDown = ({
  name,
  options,
  title,
  callback,
  selectedValue,
  includeAll = true,
}) => {
  let allOption = ""
  if (includeAll) {
    allOption = (
      <option key="alla" value="alla">
        alla
      </option>
    )
  }
  return (
    <label htmlFor={title}>
      {title + ": "}
      <select
        name={title}
        id={name}
        onChange={e => callback(e)}
        value={selectedValue}
      >
        {allOption}
        {Object.entries(options).map((key_value, index) => (
          <option key={index} value={key_value[0]}>
            {key_value[1][1]}
          </option>
        ))}
      </select>
    </label>
  )
}

const ProductsDisplay = ({ data, location }) => {
  const queryData = useStaticQuery(graphql`
    query productDisplayQuery {
      allImageSharp {
        edges {
          node {
            fixed(width: 150, height: 150) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
          }
        }
      }
      allMongodbStoreGatsbySettings {
        edges {
          node {
            category
            key
            value
          }
        }
      }
    }
  `)
  
  let queryParams = queryString.parse(location.search)

  // Get the data belonging to this page, if not specified, include
  // TODO: Update and utilize this on all sites
  let productsToShow = []

  data.edges.forEach(function (item, index) {
    if (item.node.show_on_pages.includes(location.pathname)) {
      productsToShow.push(item)
    } else if (config.siteName.toLowerCase() !== "alltomtraning") {
      productsToShow.push(item)
    } else if (location.pathname.includes("/marken/")) {
      productsToShow.push(item)
    }
  })

  return (
    <div id="productWrapper">
      <ProductGrid
        products={productsToShow}
        images={queryData.allImageSharp}
        queryParams={queryParams}
        gatsbySettings={queryData.allMongodbStoreGatsbySettings}
      ></ProductGrid>
    </div>
  )
}

class ProductGrid extends React.Component {
  static defaultProps = {
    initialVisibleObjectsCount: 36,
    objectsToAddEachTime: 8,
  }

  constructor(props) {
    super(props)

    this.showMoreFunction = this.showMoreFunction.bind(this)
    this.topFunction = this.topFunction.bind(this)
    this.addMoreProducts = this.addMoreProducts.bind(this)
    this.filterSelectCallback = this.filterSelectCallback.bind(this)
    this.handleScrollToElement = this.handleScrollToElement.bind(this)
    this.fetchPropertyTuples = this.fetchPropertyTuples.bind(this)
    this.fetchFilteredProducts = this.fetchFilteredProducts.bind(this)
    this.fetchAllValues = this.fetchAllValues.bind(this)
    this.sortChangeCallback = this.sortChangeCallback.bind(this)

    this.scrolled = false

    let tmpFilterDict = {}

    props.gatsbySettings.edges.forEach(function (item, index) {
      if (item.node.category === "filter_dict") {
        tmpFilterDict[item.node.key] = item.node.value
      }
    })
    this.filterDict = tmpFilterDict

    let tmpSortDict = {}

    props.gatsbySettings.edges.forEach(function (item, index) {
      if (item.node.category === "sort_dict") {
        tmpSortDict[item.node.key] = item.node.value.split(";")
      }
    })
    this.sortDict = tmpSortDict

    let self = this
    this.allValues = {}
    let filters = {}
    let filtersUniqueTuples = {}

    for (const [key] of Object.entries(this.filterDict)) {
      self.allValues[key] = self.fetchAllValues(self.props.products, key)
      filters[key] = self.props.queryParams[key]
        ? self.props.queryParams[key]
        : "alla"
      filtersUniqueTuples[key] = self.fetchPropertyTuples(
        self.fetchFilteredProducts(
          "alla",
          self.props.queryParams[key] ? self.props.queryParams[key] : "alla",
          key
        ),
        key
      )
    }

    this.state = {
      visibleObjectsCount: this.props.initialVisibleObjectsCount,
      filters: filters,
      filtersUniqueTuples: filtersUniqueTuples,
      showScrollToTopBtn: false,
      sorting: Object.keys(self.sortDict)[0],
    }
  }

  fetchAllValues(products, property_key) {
    let values = new Set()
    let propKeyArr = property_key.split(".")
    products.forEach(function (node, index) {
      let current_node = node.node
      propKeyArr.forEach(function (propKey, propIndex) {
        current_node = current_node[propKey]
      })
      values.add(current_node)
    })
    let valuesArr = Array.from(values)

    if (typeof valuesArr[0] === "number") {
      valuesArr.sort(function (a, b) {
        return a - b
      })
    } else {
      valuesArr.sort()
    }

    return valuesArr
  }

  fetchPropertyTuples(products, property_key) {
    let uniquePropertyDict = {}
    this.allValues[property_key].forEach(function (value, index) {
      uniquePropertyDict[value] = 0
    })
    let propKeyArr = property_key.split(".")
    products.forEach(function (node, index) {
      let current_node = node.node
      propKeyArr.forEach(function (propKey, propIndex) {
        current_node = current_node[propKey]
      })

      let property = current_node
      if (!(property in uniquePropertyDict)) {
        uniquePropertyDict[property] = 0
      }
      uniquePropertyDict[property] += 1
    })

    var uniquePropertyTuples = Object.keys(uniquePropertyDict).map(function (
      key
    ) {
      return [key, uniquePropertyDict[key]]
    })

    return uniquePropertyTuples
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollToElement)
  }

  handleScrollToElement() {
    if (!this.scrolled) {
      this.scrolled = true

      let visible_products = document.getElementsByClassName("product")
      if (visible_products.length > 0) {
        let last_visible_element_position =
          visible_products[visible_products.length - 1].getBoundingClientRect()
            .top

        let clientHeight = document.documentElement.clientHeight

        if (last_visible_element_position - 1.2 * clientHeight < 0) {
          this.showMoreFunction()
        }
      }

      var top = window.pageYOffset || document.documentElement.scrollTop
      var height = window.innerHeight || document.documentElement.clientHeight

      this.setState(state => ({
        showScrollToTopBtn: top > height,
      }))

      this.scrolled = false
    }
  }

  showMoreFunction() {
    this.addMoreProducts(this.props.objectsToAddEachTime)
  }

  addMoreProducts(count) {
    this.setState(state => ({
      visibleObjectsCount: state.visibleObjectsCount + count,
    }))
  }

  sortChangeCallback(e) {
    this.setState(state => ({
      sorting: e.target.value,
    }))
  }

  filterSelectCallback(e) {
    let filterKey = e.target.id
    let value = e.target.value
    let newFilter = { ...this.state.filters }
    newFilter[filterKey] = value

    let newFiltersUniqueTuples = { ...this.state.filtersUniqueTuples }
    for (const [key] of Object.entries(this.state.filtersUniqueTuples)) {
      if (key !== filterKey) {
        newFiltersUniqueTuples[key] = this.fetchPropertyTuples(
          this.fetchFilteredProducts(key, newFilter),
          key
        )
      }
    }

    this.setState(state => ({
      filters: newFilter,
      visibleObjectsCount: this.props.initialVisibleObjectsCount,
      filtersUniqueTuples: newFiltersUniqueTuples,
    }))
  }

  topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  fetchFilteredProducts(ignoreKey, filters) {
    let state = this.state
    let filteredObjects = this.props.products
    if (typeof state !== "undefined") {
      let filtersToUse =
        typeof filters !== "undefined" ? filters : state.filters

      filteredObjects = this.props.products.filter(function (p) {
        for (const [key, value] of Object.entries(filtersToUse)) {
          let propKeyArr = key.split(".")

          let current_node = p.node
          propKeyArr.forEach(function (propKey, propIndex) {
            current_node = current_node[propKey]
          })

          if (key !== ignoreKey && value !== "alla" && current_node != value) {
            return false
          }
        }
        return true
      })

      let sorting = this.sortDict[state.sorting]
      function compare(a_par, b_par) {
        let a = a_par.node
        let b = b_par.node
        var a_comp = Array.isArray(a[sorting[0]])
          ? Math.min(...a[sorting[0]])
          : a[sorting[0]]
        var b_comp = Array.isArray(b[sorting[0]])
          ? Math.min(...b[sorting[0]])
          : b[sorting[0]]

        if (a_comp < b_comp) {
          return sorting[2] === "asc" ? -1 : 1
        }
        if (a_comp > b_comp) {
          return sorting[2] === "asc" ? 1 : -1
        }
        return 0
      }
      filteredObjects.sort(compare)
    }

    return filteredObjects
  }

  render() {
    let filteredObjects = this.fetchFilteredProducts()

    let visibleObjects = filteredObjects.slice(
      0,
      this.state.visibleObjectsCount
    )

    let showMoreBtn = ""
    if (this.state.visibleObjectsCount < filteredObjects.length) {
      showMoreBtn = (
        <button
          onClick={this.showMoreFunction}
          id="showMoreBtn"
          className={
            this.props.products.length === this.state.visibleObjectsCount
              ? "hidden"
              : ""
          }
          title="Visa Mer"
        >
          Visa mer
        </button>
      )
    }

    let scrollToTopBtn = ""
    if (this.state.showScrollToTopBtn) {
      scrollToTopBtn = (
        <button
          onClick={this.topFunction}
          id="scrollToTopBtn"
          title="Gå till toppen"
        >
          Till Toppen
        </button>
      )
    }

    let noProductFound = ""
    if (visibleObjects.length === 0) {
      noProductFound = (
        <span className={"info"}>Inga produkter matchade din sökning.</span>
      )
    }

    let cardDetails = []
    this.props.gatsbySettings.edges.forEach(function (item, index) {
      if (item.node.category === "card_details") {
        cardDetails.push(item.node)
      }
    })
    let miscSettings = {}
    this.props.gatsbySettings.edges.forEach(function (item, index) {
      if (item.node.category === "misc") {
        miscSettings[item.node.key] = item.node.value
      }
    })

    return (
      <div id="productWrapper">
        <i>
          Observer att alla produkter nedan består av så kallade
          affiliate-länkar. Detta betyder att du kommer till en butik när du
          klickar på en produkt och vi får provision om du genomför ett köp.
        </i>
        <div id="filter">
          {Object.entries(this.filterDict).map((key_value, index) => (
            <div className="filterWrapper" key={index}>
              <DropDown
                name={key_value[0]}
                title={key_value[1]}
                options={this.state.filtersUniqueTuples[key_value[0]]}
                callback={this.filterSelectCallback}
                selectedValue={this.state.filters[key_value[0]]}
              ></DropDown>
            </div>
          ))}
          <div className="divider"></div>
          <div className="sortingWrapper">
            <SortDropDown
              name="sorting"
              title="Sortering"
              options={this.sortDict}
              callback={this.sortChangeCallback}
              selectedValue={this.state.sorting}
              includeAll={false}
            ></SortDropDown>
          </div>
        </div>
        <div className="productList">
          {noProductFound}
          {visibleObjects.map((product, index) => {
            return (
              <ProductCard
                key={product.node.id}
                product={product.node}
                allImageData={this.props.images}
                cardDetails={cardDetails}
                miscSettings={miscSettings}
              ></ProductCard>
            )
          })}
          {scrollToTopBtn}
          <div className="center">{showMoreBtn}</div>
        </div>
      </div>
    )
  }
}

export default ProductsDisplay
